
import React, { useState } from 'react';

export const useSubmitForm = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
  
    const submitForm = async (formData) => {
      setLoading(true);
      setError('');
      setSuccess(false);
        console.log(formData)
      try {
        // Send a POST request to the Firebase function
        const response = await fetch('https://storeformdata-scp75a6qja-uc.a.run.app', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            receiver: {
                email: 'info@kshaarstyliste.be',
                name: 'Kimberly Smeulders'
            },
            sender: {
                ...formData
            }
        }),
        });
  
        const result = await response.json();
        
        if (response.ok) {
          // Handle success
          setSuccess(true);        } else {
          // Handle error
          setError(result.error || 'Error submitting form');
        }
      } catch (err) {
        console.error(err);
        setError('Error submitting form. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    return { submitForm, loading, error, success };
  };
